<template>
  <div id="footer" :class="{ mainpageFooter: isMainPageFooter }">
    <ul>
      <li>
        <a href="terms.html">Terms of Service</a>
      </li>
      <li>
        <a href="privacy.html">Privacy</a>
      </li>
      <li>
        <a href="contact.html">Contact Us</a>
      </li>
      <!-- <li>
        <a href="extension">Extension</a>
      </li> -->
      <li>
        <a href="settings.html">Settings</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isMainPageFooter: Boolean,
  },
};
</script>

<style>
#footer {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 50px;
  margin-top: 20px;
  padding: 0 25px;
}

#footer ul {
  list-style: none;
  display: inline-flex;
}

#footer ul li {
  margin: 0 15px;
}

#footer ul li a {
  text-decoration: none;
  color: #949494;
  text-transform: uppercase;
}

#footer ul li a:hover {
  opacity: 0.7;
}

.mainpageFooter {
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
}
</style>