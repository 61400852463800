import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/websearch.html',
    name: 'WebSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebSearch.vue'),
    props: (route) => (
      { 
        searchTerm: route.query.q,
        apiURL: 'search',
        pageToken: route.query.pt !=undefined ? route.query.pt : '',
        // offset: Number(route.query.offset) >0 ? Number(route.query.offset) : 0,
        channel: route.query.n !== undefined && route.query.n !== null ? route.query.n : '',
     })
  },
  {
    path: '/t/websearch.html',
    name: 'WebSearchTaboola',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebSearch.vue'),
    props: (route) => (
      { 
        searchTerm: route.query.q,
        apiURL: 'search_taboola',
        pageToken: route.query.pt !=undefined ? route.query.pt : '',
        // offset: Number(route.query.offset) >0 ? Number(route.query.offset) : 0,
        channel: route.query.n !== undefined && route.query.n !== null ? route.query.n : '',
     })
  },
  {
    path: '/settings.html',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
