<template>
  <div id="wrapper">
    <div id="nav" class="main">
      <div class="hamburger" @click="isModalMenuVisible = !isModalMenuVisible">
        <svg
          enable-background="new 0 0 375.636 375.635"
          version="1.1"
          viewBox="0 0 375.636 375.635"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m41.013 228.82c-22.617 0-41.013-18.387-41.013-41.007 0-22.608 18.396-41.007 41.013-41.007s41.013 18.398 41.013 41.007c-1e-3 22.62-18.396 41.007-41.013 41.007z"
          />
          <path
            d="m185.51 228.82c-22.617 0-41.013-18.387-41.013-41.007 0-22.608 18.396-41.007 41.013-41.007 22.613 0 41.013 18.398 41.013 41.007-1e-3 22.62-18.4 41.007-41.013 41.007z"
          />
          <path
            d="m334.62 228.82c-22.613 0-41.013-18.387-41.013-41.007 0-22.608 18.399-41.007 41.013-41.007s41.013 18.398 41.013 41.007c0 22.62-18.399 41.007-41.013 41.007z"
          />
        </svg>
      </div>
      <div v-if="isModalMenuVisible" id="modalmenu">
        <ul>
          <li>
            <a href="terms.html">Terms of Service</a>
          </li>
          <li>
            <a href="privacy.html">Privacy</a>
          </li>
          <li>
            <a href="contact.html">Contact Us</a>
          </li>
          <li>
            <a href="settings.html">Settings</a>
          </li>
        </ul>
      </div>
    </div>

    <div id="content" class="mainpage">
      <div class="logo"></div>
      <form class="searchbox" action="websearch.html">
        <input
          type="text"
          class="searchTerm"
          placeholder="What are you looking for?"
          name="q"
          id="q"
        />
        <button type="submit" class="searchButton">
          <svg
            enable-background="new 0 0 250.313 250.313"
            version="1.1"
            viewBox="0 0 250.313 250.313"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m244.19 214.6l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76 10.7-16.231 16.945-35.66 16.945-56.554 0-56.837-46.075-102.91-102.91-102.91s-102.91 46.075-102.91 102.91c0 56.835 46.074 102.91 102.91 102.91 20.895 0 40.323-6.245 56.554-16.945 0.269 0.301 0.47 0.64 0.759 0.929l54.38 54.38c8.169 8.168 21.413 8.168 29.583 0 8.168-8.169 8.168-21.413 0-29.582zm-141.28-44.458c-37.134 0-67.236-30.102-67.236-67.235 0-37.134 30.103-67.236 67.236-67.236 37.132 0 67.235 30.103 67.235 67.236s-30.103 67.235-67.235 67.235z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            />
          </svg>
        </button>
      </form>
      <SearchCategories activePage="index" />
    </div>

    <Footer :isMainPageFooter="true"></Footer>
  </div>
</template>

<script>
import SearchCategories from "../components/SearchCategories";
import Footer from "../components/Footer";
export default {
  name: "Index",
  components: {
    SearchCategories,
    Footer,
  },
  data: () => ({
    isModalMenuVisible: false,
  }),
};
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.mainpage {
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
}

.logo {
  background: url("../assets/img/logoinner_karmas.png") no-repeat center left;
  background-size: contain;
  height: 50px;
  width: 180px;
}

.mainpage .logo {
  background: url("../assets/img/logo_karmas.gif") no-repeat center left;
  background-size: contain;
  background-position: center;
  height: 140px;
  margin-bottom: 20px;
}

.mainpage .searchbox {
  justify-content: center;
}

.searchbox {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchbox input {
  width: 100%;
  max-width: 680px;
  border: 1px solid #ddd;
  border-radius: 60px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 25px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  letter-spacing: -0.3px;
  font-family: "Poppins", sans-serif;
}

.searchbox input:focus {
  border: 1px solid #949494;
  border-radius: 70px;
  outline: none;
  font-size: 16px;
}

::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #aaa;
  font-size: 16px;
  letter-spacing: -0.3px;
}

.searchbox button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -45px;
  border: none;
  padding: 13px;
  background: #eee;
  transition: all 0.3s ease;
  cursor: pointer;
}

.searchbox button svg {
  fill: #3d3d3d;
  transition: all 0.2s ease;
}

.searchbox button:hover svg {
  fill: #e13e34;
}

.mainpage .searchbox input {
  height: 70px;
  font-size: 16px;
  margin: 20px 0;
}

.mainpage .searchbox input:focus {
  border: 1px solid #949494;
  border-radius: 70px;
  outline: none;
}

.mainpage ::placeholder {
  font-size: 16px;
}

.mainpage .searchbox button {
  width: 50px;
  height: 50px;
  padding: 17px;
  margin-left: -61px;
}

.navsearch .install {
  top: calc(50% - 20px);
}

.install {
  background: url('../assets/img/puzzle.svg') no-repeat center left 20px, linear-gradient(135deg, rgba(33,128,137,1) 0%,rgba(40,174,57,1) 70%);
  background-size: 18px, auto;
  border-radius: 60px;
  color: #fff;
  padding: 0 20px 0 45px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: 110px;
  top: 20px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.install:hover {
  -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
}

.mainPageNav {
  box-shadow: none !important;
  border-bottom: none !important;
}

.mainPageNav .hamburger {
  position: absolute;
  top: 20px;
  right: 40px;
}
#nav {
  -webkit-box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.05);
}
#nav .wrap {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.hamburger {
  position: absolute;
  right: 30px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  background: #eee;
  border-radius: 50%;
}

.hamburger svg {
  fill: #3d3d3d;
  transition: fill 0.2s ease;
}

.hamburger:hover svg {
  fill: #e13e34;
}

#modalmenu {
  position: absolute;
  right: 30px;
  top: 90px;
  width: 250px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  /* display: none;s */
  font-size: 14px;
  z-index: 101;
}

#modalmenu ul {
  list-style: none;
}

#modalmenu ul li {
  line-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  transition: background 0.3s ease;
}

#modalmenu ul li:hover {
  background: #f7f7f7;
}

#modalmenu ul li:last-child {
  border: none;
}
#modalmenu a {
  color: #2a2a2a;
  text-decoration: none;
  transition: all 0.3s ease;
}

#modalmenu a:hover {
  color: #e14036;
}


</style>