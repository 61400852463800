<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    this.$store.commit('initiateStore')
  }
};
</script>

<style>
#app {
  height: 100%;
}
@import "./assets/style.css";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
</style>
