import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    market: 'en-US'
  },
  mutations: {
    initiateStore(state) {
      console.log('initiateStore')
      if (localStorage.getItem('market')) {
        state.market = localStorage.getItem('market')
        console.log(`setting state.market=${state.market}`)
        
      }
    },
    setMarket(state, value) {
      console.log(`setMarket(${state}, ${value})`)
      localStorage.setItem('market', value)
      state.market = value
    }
  },
  actions: {
    
  },
  modules: {
  }
})
